//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import addToCartMixin from '~/mixins/addToCart';

export default {
    mixins: [addToCartMixin],
    props: ['product', 'added'],
    data() {
        return {};
    },
    methods: {
        async submit(product, e) {
            e.preventDefault();
            const payload = {
                sku: product.sku,
                quantity: 1,
            };
            this.errors = [];
            const { cart, user_errors } = await this.$store.dispatch('cart/addProduct', {
                items: [payload],
                product,
            });
            this.errors = user_errors;
            if (!this.errors.length) {
                this.setShowModal();
                this.$emit('productAdded');
            }
        },
    },
};
