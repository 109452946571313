//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: ['product'],
    data() {
        return {
            qty: 1,
            added: [],
            showAllProducts: false,
            maxProducts: 4,
        };
    },
    methods: {
        showAll() {
            this.showAllProducts = true;
        },
        productAdded(product) {
            this.added.push(product.sku);
            this.$notify.success(this.$t('catalog.crossell.add.notification'));
        },
    },
};
